<template>
  <div class="content md flex-box vertical">
    <div class="area">
      <a-form-model ref="ruleForm" :model="form" :rules="rules" layout="vertical" label-align="left" class="flex-grow">
        <!-- <a-form-model-item label="申购单编号" prop="subscribe_num" extra="仅允许输入英文、数字、下划线">
          <a-input v-model="form.subscribe_num" placeholder="输入申购单编号" allowClear></a-input>
        </a-form-model-item> -->
        <a-form-model-item label="堂口名称" prop="tangkou_id">
          <a-select
            placeholder="选择堂口"
            :options="tkOptions"
            v-model="form.tangkou_id"
            allowClear
            show-search
            option-filter-prop="children"
            @change="tangkouChange"
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="部门主管" prop="tangkou_director">
          <a-select placeholder="选择部门主管" v-model="form.tangkou_director" allowClear>
            <a-select-option :value="director" v-for="director in directorList" :key="director">
              <open-data type="userName" :openid="director" />
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="申购日期" prop="subscribe_date">
          <a-date-picker
            v-model="form.subscribe_date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="选择日期"
            allowClear
            style="width: 100%;"
            :disabled-date="disabledDate"
          />
        </a-form-model-item>
        <a-form-model-item label="申购附件">
          <form-upload
            v-model="form.annex"
            list-type="text"
            :limit="9"
            :size="50 * 1024 * 1024 * 1"
            customAccept=".xls,.xlsx"
            uploadTip="仅支持.xls,.xlsx后缀的EXCEL文件"
            folder-prefix="purchase/"
            save-file-name
          ></form-upload>
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea
            v-model="form.notes"
            placeholder="填写申购备注"
            :autoSize="{ minRows: 4, maxRows: 6 }"
            :maxLength="200"
            allowClear
          ></a-textarea>
        </a-form-model-item>

        <a-form-model-item class="info-list" prop="add_goods" :auto-link="false">
          <a-space>
            <a-button type="primary" @click="addInfo">添加商品</a-button>
            <upload-file
              uploadText="导入模板"
              type="primary"
              url="/admin/asset-subscribe/import"
              @change="getList"
            ></upload-file>
            <a-button @click="downloadTemplate">下载模板</a-button>
          </a-space>
          <a-table
            :columns="columns"
            :data-source="form.add_goods"
            class="info-table"
            :row-key="(record) => record.key"
          >
            <div class="row-btn" slot="action" slot-scope="text, record, index">
              <a class="txt-btn" @click.stop="editRow(record, index)">编辑</a>
              <a class="txt-btn danger" @click.stop="delRow(index)">删除</a>
            </div>
          </a-table>
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-space class="footer">
      <a-button type="primary" @click="handleSave">保存</a-button>
    </a-space>
    <BasicModal
      :title="`${edit ? '编辑' : '添加'}申购商品`"
      :initial="single"
      v-model="visible"
      :rules="editRules"
      :footer="edit ? 1 : 2"
      :success="addSuccess"
    >
      <template v-slot:edit="{ form }">
        <a-form-model-item label="商品名称" prop="goods_name">
          <a-auto-complete
            placeholder="输入商品名称查询"
            :data-source="goodsOptions"
            v-model="form.goods_name"
            :open.sync="open"
            @change="goodsChange($event, form)"
            @search="goodsSearch"
            @select="goodsSelect"
            @blur="blur"
            @focus="focus"
          >
            <a-input>
              <a-spin slot="suffix" size="small" v-show="fetching" />
            </a-input>
          </a-auto-complete>
        </a-form-model-item>
        <a-form-model-item label="商品单位" prop="subscribe_goods_unit">
          <a-input placeholder="输入商品单位" v-model="form.subscribe_goods_unit" allowClear> </a-input>
        </a-form-model-item>
        <a-form-model-item label="申购数量" prop="num">
          <a-input-number
            v-model="form.num"
            placeholder="请输入数字"
            :min="1"
            :step="1"
            allowClear
            style="width: 100%;"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item label="单价">
          <a-input-number
            v-model="form.unit_price"
            placeholder="请输入"
            :min="0"
            :step="0.01"
            allowClear
            style="width: 100%;"
          ></a-input-number>
        </a-form-model-item>
      </template>
    </BasicModal>
  </div>
</template>
<script>
import moment from "moment";
import {
  clone,
  dataConvertOptions,
  downLoadFile,
  isEmpty,
  randomString,
  stringConvertTimestamp,
  debounce,
} from "../../common/js/tool";
import BasicModal from "./components/BasicModal.vue";
import { purchaseTemplateUrl } from "../../common/asset/template";
export default {
  name: "PurchaseAdd",
  components: {
    BasicModal,
  },
  data() {
    this.lastFetchId = 0;
    this.goodsSearch = debounce(this.goodsSearch, 300);
    return {
      form: {
        subscribe_date: new Date().pattern("yyyy-MM-dd"),
      },
      rules: {
        // subscribe_num: [{ message: "请输入申购单编号", trigger: "blur", pattern: /^[A-Za-z0-9_]+$/ }],
        tangkou_id: [{ required: true, message: "请选择堂口", trigger: "change" }],
        tangkou_director: [{ required: true, message: "请选择部门主管", trigger: "change" }],
        subscribe_date: [{ required: true, message: "请选择申购日期", trigger: "change" }],
        add_goods: [{ required: true, message: "请添加商品", trigger: "change" }],
      },
      tkOptions: [],
      columns: [
        { title: "商品名称", dataIndex: "goods_name" },
        { title: "商品单位", dataIndex: "subscribe_goods_unit" },
        { title: "商品数量", dataIndex: "num" },
        { title: "商品单价", dataIndex: "unit_price" },
        {
          title: "商品金额",
          customRender: (text) => {
            const { num = 0, unit_price = 0 } = text;
            return num * unit_price;
          },
        },
        { title: "操作", dataIndex: "action", scopedSlots: { customRender: "action" } },
      ],
      visible: false,
      single: null,
      editIndex: undefined,
      editRules: {
        goods_name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        subscribe_goods_unit: [{ required: true, message: "请输入商品单位", trigger: "blur" }],
        num: [{ required: true, message: "请输入申购数量", trigger: "blur" }],
      },
      directorList: [],

      open: false,
      fetching: false,
      goodsOptions: [],
    };
  },
  computed: {
    edit() {
      return this.single;
    },
  },
  created() {
    this.getTk();
  },
  methods: {
    getTk() {
      this.$axios({
        url: "/admin/ketang",
        method: "GET",
        params: {
          sort: "order,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.tkOptions = dataConvertOptions(res.data);
      });
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    /* 查询商品名称 */
    goodsSearch(keyword) {
      if (isEmpty(keyword)) {
        this.goodsOptions = [];
      } else {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.goodsOptions = [];
        this.fetching = true;
        this.$axios({
          url: "/admin/asset-goods",
          method: "GET",
          params: {
            sort: "-id",
            expand: "goodsUnit",
            page: 1,
            pageSize: 100,
            "filter[name][like]": keyword,
          },
        })
          .then((res) => {
            if (fetchId !== this.lastFetchId) {
              // for fetch callback order
              return;
            }
            this.goodsOptions = res.data.map((item) => {
              return {
                ...item,
                value: item.name,
                text: item.name,
              };
            });
            this.open = true;
          })
          .finally(() => {
            this.fetching = false;
          });
      }
    },
    goodsSelect() {
      this.open = false;
    },
    goodsChange(val, form) {
      if (!isEmpty(val)) {
        let target = this.goodsOptions.find((item) => item.name === val);
        if (target) {
          let goodsUnit = target.goodsUnit;
          this.$set(form, "subscribe_goods_unit", goodsUnit.name);
        }
      }
    },
    focus() {
      if (this.goodsOptions?.length) {
        this.open = true;
      }
    },
    blur() {
      this.open = false;
    },
    // 堂口改变
    tangkouChange(val) {
      this.$set(this.form, "tangkou_director", undefined);
      if (val) {
        let target = this.tkOptions.find((item) => item.value === val);
        this.directorList = target.admin_user_ids?.split(",") || [];
        this.$set(this.form, "tangkou_director", this.directorList[0]);
      } else {
        this.directorList = [];
      }
    },
    // 编辑
    editRow(record, index) {
      this.single = record;
      this.editIndex = index;
      this.visible = true;
    },
    delRow(index) {
      this.$confirm({
        title: "提示",
        content: `确定删除此商品吗？`,
        onOk: () => {
          this.form.add_goods.splice(index, 1);
          this.validateAddGoods();
        },
      });
    },
    handleSave() {
      let ruleForm = this.$refs.ruleForm;
      ruleForm.validate((flag) => {
        if (flag) {
          this.$confirm({
            title: "提示",
            content: `确定保存此申购单吗？`,
            onOk: () => {
              let form = clone(this.form);
              if (form.subscribe_date) {
                form.subscribe_date = stringConvertTimestamp(form.subscribe_date);
              }
              if (form.annex) {
                form.annex = JSON.stringify(form.annex);
              }
              this.$axios({
                url: "/admin/asset-subscribe/create-goods",
                method: "POST",
                data: form,
              }).then((res) => {
                if (res.error == 0) {
                  this.$message.success("添加成功！");
                  this.form = {
                    subscribe_date: new Date().pattern("yyyy-MM-dd"),
                  };
                } else {
                  this.$message.error(res.msg);
                }
              });
            },
          });
        }
      });
    },
    // 清除编辑参数
    clearEdit() {
      this.single = null;
      this.editIndex = undefined;
    },
    addInfo() {
      this.clearEdit();
      this.visible = true;
    },
    addSuccess(form, close) {
      this.$set(this.form, "add_goods", this.form.add_goods || []);
      if (form.key) {
        // 编辑
        this.form.add_goods[this.editIndex] = { unit_price: 0, amount: 0, ...form };
      } else {
        // 新增
        this.form.add_goods.push({ key: randomString(16), unit_price: 0, amount: 0, ...form });
      }
      this.validateAddGoods();
      close();
    },
    downloadTemplate() {
      downLoadFile(purchaseTemplateUrl, "商品模板");
    },
    getList(list) {
      let add_goods = list.map((item) => {
        return {
          ...item,
          key: randomString(16),
          goods_name: item.subscribe_goods,
        };
      });
      this.$set(this.form, "add_goods", add_goods);
      this.validateAddGoods();
    },
    validateAddGoods() {
      this.$refs.ruleForm.validateField("add_goods");
    },
  },
};
</script>

<style lang="less" scoped>
.info-table {
  margin-top: 12px;
}
</style>
